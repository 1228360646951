import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>Política de Privacidade</h2>
                <p>
                  <strong>
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>A JNTL Consumer Health (Portugal) Limitada</strong> preocupa-se com a sua privacidade e quer que você esteja 
                  familiarizado com a forma como recolhemos, utilizamos e divulgamos informações.  
                  Esta Política de Privacidade descreve as nossas práticas no âmbito das informações que nós 
                  recolhemos ou os nossos prestadores de serviços recolhem através do website ou da aplicação 
                  (doravante designados por "Serviço") operado e controlado por nós, a partir do qual está a 
                  aceder a esta Política de Privacidade.  Ao disponibilizar-nos informações pessoais 
                  ou ao utilizar o Serviço, você confirma que leu e compreendeu esta Política de Privacidade.
                  
                </p>
                <h2>UTILIZAÇÃO POR MENORES</h2>
                <p>
                O Serviço não é direcionado para indivíduos com idade inferior a 13 anos e 
                solicitamos que estes indivíduos não forneçam informações pessoais através 
                do Serviço. Se o seu filho tiver submetido Informações pessoais e você 
                gostaria de solicitar que tais Informações pessoais fossem removidas, 
                contacte-nos conforme explicado abaixo em Contactar-nos.
                </p>
                <h2>RECOLHA DE INFORMAÇÕES</h2>
                <p>
                Podemos pedir-lhe que submeta informações pessoais para poder beneficiar 
                de determinadas funcionalidades (tais como subscrições de newsletter, 
                sugestões/indicadores ou processamento de pedidos) ou para participar 
                numa atividade específica (tais como sorteios ou outras promoções). Será informado de quais 
                as informações necessárias e que informações são opcionais.
                </p>
                <p>
                Podemos combinar as informações que submeter com outras informações que recolhermos sobre 
                si, quer online quer offline, incluindo, por exemplo, o seu histórico de compras. 
                Podemos igualmente combiná-las com 
                informações que recebemos sobre si de empresas do Grupo Kenvue.
                </p>
                <p>
                Se nos submeter informações pessoais relacionadas com outro indivíduo, você declara 
                que tem autoridade para o fazer e que nos autoriza a 
                utilizar as informações em conformidade com esta Política de Privacidade.
                </p>
                <h2>INFORMAÇÕES SENSÍVEIS</h2>
                <p>
                Exceto se especificamente o solicitarmos ou o convidarmos a tal, pedimos-lhe que 
                não nos envie nem divulgue informações pessoais sensíveis (por ex., números 
                da Segurança Social, informações relacionadas com a origem racial ou 
                étnica, opiniões políticas, religião ou crenças filosóficas, condições 
                clínicas e de saúde, vida sexual ou orientação sexual, registo criminal 
                ou filiação sindical, dados biométricos ou genéticos) 
                no ou através do Serviço ou de qualquer outra forma.
                </p>
                
                  <h2>
                    RECOLHA AUTOMÁTICA DE INFORMAÇÕES E UTILIZAÇÃO{" "}
                  </h2>
                <p>
                Nós, e os nossos prestadores de serviços, podemos recolher determinadas 
                informações automaticamente enquanto navega pelo Serviço. Leia a <a href="/pt/legal/politicia-de-cookies/" target="_blank">Política de Cookies</a>{" "}para obter informações detalhadas sobre os cookies e outras tecnologias de rastreio 
                utilizadas no Serviço. Salvo quando permitido por lei, colocamos cookies apenas após 
                termos recebido o seu consentimento através do banner de cookies ou centro de gestão de preferências. 
                A Política de Cookies inclui informação sobre como desativar estas tecnologias.
                </p>
                <p>
                Nós e os nossos prestadores de serviços também podemos recolher automaticamente e utilizar informações através das seguintes formas:
                </p>
               
                <ul>
                  <li>
                    <strong>Através do seu navegador:</strong> Algumas informações são recolhidas pela maioria 
                    dos navegadores, tais como o endereço de controlo de acesso a suportes de dados (MAC), 
                    o tipo de computador (Windows ou Mac), resolução de ecrã, nome e versão do sistema 
                    operativo e tipo e versão do navegador Internet.  Podemos recolher informações similares, 
                    tais como o tipo e identificador do dispositivo, se aceder ao Serviço através de um dispositivo móvel.  
                    Utilizamos estas informações para assegurar que o Serviço funciona corretamente.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Endereço de IP:</strong> O seu endereço de IP é um número que é automaticamente atribuído 
                    ao seu computador pelo Fornecedor de serviços de Internet.  Um endereço de IP é identificado 
                    e registado automaticamente nos ficheiros de registo do nosso servidor sempre que um utilizador 
                    consulta o Serviço, assim como a duração da consulta e as páginas consultadas.  A recolha de 
                    endereços de IP é uma prática normalizada e é feita automaticamente por muitos serviços online.  
                    Utilizamos os endereços de IP para fins como o cálculo dos níveis de utilização do Serviço, 
                    diagnóstico de problemas no servidor e administração do Serviço.  
                    É igualmente possível aferir a sua localização aproximada a partir do endereço de IP.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Informações do dispositivo: </strong>Podemos recolher informações sobre o seu 
                    dispositivo móvel, tais como um 
                    identificador de dispositivo único, para compreender como utiliza o Serviço.
                  </li>
                </ul>
                <h2>COMO UTILIZAMOS E DIVULGAMOS INFORMAÇÕES</h2>
                <p>
                Utilizamos e divulgamos as informações que nos disponibiliza conforme descrito na secção de recolha.
                Consulte a secção intitulada "Escolhas e Acesso" em seguida para saber 
                como pode anular determinadas utilizações e divulgações.
                </p>
                <p>
                Quando exigido por lei, obteremos o seu consentimento para utilizar os seus dados
                pessoais na secção de recolha de informações. Podemos igualmente utilizar 
                informações suas ou sobre si conforme necessário para celebrar um contrato, 
                cumprir uma obrigação legal (por exemplo, devido às nossas obrigações de farmacovigilância) 
                ou para a prossecução dos nossos interesses comerciais legítimos.
                Podemos também contar com outras bases legais, especificamente para:
                </p>
                <ul>
                  <li>
                    <strong>
                      Fornecer a funcionalidade do Serviço e satisfazer os seus pedidos.
                    </strong>
                  </li>
                  <li>
                    fornecer a funcionalidade do Serviço e fornecer-lhe serviços ao cliente relacionados;
                  </li>
                  <li>
                  responder às suas questões e satisfazer os seus pedidos, como por exemplo, 
                  enviar-lhe os documentos que solicitar ou alertas de e-mail;
                  </li>
                  <li>
                  enviar-lhe informações importantes relativamente à nossa relação consigo ou relativamente ao Serviço, alterações 
                  aos nossos termos, condições e políticas e/ou outras informações administrativas.
                    <br />
                    <br />
                    Realizaremos estas atividades para gerir a nossa relação contratual consigo e/ou cumprir uma obrigação legal.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    <strong>Alcançar os nossos objetivos comerciais.</strong>
                  </li>
                  <li>
                   análise de dados, por exemplo, para melhorar a eficiência do Serviço;
                  </li>
                  <li>
                  auditorias, para verificar se os nossos processos internos funcionam 
                  como pretendido e estão em conformidade com os requisitos legais, regulamentares ou contratuais;
                  </li>
                  <li>
                  fins de monitorização de fraude e segurança, por exemplo, 
                  para detetar e prevenir ataques cibernéticos ou tentativas de roubo de identidade;
                  </li>
                  <li>o desenvolvimento de novos produtos e serviços;</li>
                  <li>
                    potenciar, melhorar ou modificar o nosso site ou produtos e serviços;{" "}
                  </li>
                  <li>
                    identificar as tendências de uso do serviço, por exemplo, 
                    para entender que aspetos do nosso Serviço são de maior interesse para os utilizadores; e
                  </li>
                  <li>
                    determinar a eficácia das nossas campanhas promocionais, 
                    para que possamos adaptar as nossas campanhas às 
                    necessidades e interesses dos nossos utilizadores.
                    <br />
                    <br />
                    Realizaremos estas atividades para gerir a nossa relação 
                    contratual consigo, para cumprir uma obrigação legal 
                    e/ou porque temos um interesse legítimo.
                  </li>
                </ul>
                <div> </div>
                <ul>
                  <li>
                    <strong>
                      Análise de Informações Pessoais para fornecer serviços
                      personalizados.
                    </strong>
                  </li>
                  <li>
                    compreendê-lo melhor para que possamos personalizar as
                    nossas interações consigo e fornecer informações e/ou
                    ofertas adaptadas aos seus interesses;
                  </li>
                  <li>
                    entender melhor as suas preferências para que possamos
                    fornecer conteúdo através do Serviço que acreditamos ser
                    relevante e interessante para si.
                    <br />
                    <br />
                    Forneceremos serviços personalizados mediante o seu
                    consentimento ou porque temos um interesse legítimo.
                  </li>
                </ul>
                <p>
                  Também divulgamos informações recolhidas através do Serviço:
                </p>
                <ul>
                  <li>
                    às nossas afiliadas para os fins descritos nesta Política de Privacidade.  
                    Poderá encontrar uma lista das nossas afiliadas em{" "}
                    <a href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx" target="_blank">https://investors.kenvue.com/financials-reports/sec-filings/default.aspx</a>{" "}
                    - aceder aos documentos apresentados pela Kenvue perante a Securities Exchange Commission. 
                    A Kenvue é a entidade responsável pela gestão das Informações pessoais utilizadas em conjunto;{" "}
                    
                  </li>
                  <li>
                	aos nossos parceiros externos com os quais oferecemos uma promoção de marca conjunta ou de comercialização conjunta;
                  </li>
                  <li>
                  aos  nossos prestadores de serviços externos que prestam serviços, tais como 
                  alojamento e moderação do website, alojamento da aplicação móvel, análise 
                  de dados, processamento de pagamentos, atendimento de pedidos, fornecimento 
                  de infraestruturas, serviços de TI, atendimento ao cliente, serviços de 
                  entrega de e-mail e mailing direto, 
                  auditorias e outros serviços, a fim de lhes permitir prestar os serviços; e
                  </li>
                  <li>
                    conforme permitido por lei, a terceiros na eventualidade de
                    uma reorganização, fusão, venda, joint venture, cedência,
                    transferência ou outra disposição da totalidade ou de uma
                    parte da nossa empresa, ativos ou ações (incluindo no âmbito
                    de qualquer processo de insolvência ou procedimentos
                    similares).
                  </li>
                </ul>
                <p> </p>
                <p>
                Adicionalmente, podemos utilizar e divulgar as suas informações conforme considerarmos 
                necessário ou apropriado: (a) a fim de cumprir qualquer processo jurídico ou lei 
                aplicável, que poderá incluir leis fora do seu país de residência; (b) conforme 
                permitido pela lei aplicável para responder a pedidos de autoridades públicas 
                e estatais, que podem incluir autoridades fora do seu país de residência; 
                (c) para implementar os nossos termos e condições; e (d) para proteger os 
                nossos direitos, privacidade, segurança ou propriedade e/ou os direitos, 
                segurança ou propriedade das nossas afiliadas, suas ou de outros.  Podemos 
                também utilizar e divulgar as suas informações 
                de outras formas, depois de obtermos o seu consentimento para tal.{" "}
                </p>
                <p>
                  Podemos utilizar e divulgar as informações que recolhemos
                  automaticamente conforme descrito na secção "Recolha
                  automática de informações e utilização".
                </p>
                <p>
                  Adicionalmente, quando permitido pela lei aplicável, podemos
                  utilizar e divulgar informações que não estejam sob a forma de
                  informações pessoais identificáveis, para qualquer fim. Se
                  combinarmos informações que não estão sob a forma de
                  informações pessoais identificáveis com informações
                  identificáveis (como por exemplo, combinar o seu nome com a
                  localização geográfica), trataremos as informações combinadas
                  como informações pessoais.
                </p>
                <h2>ESCOLHAS E ACESSO</h2>
                <p>
                  <strong>
                    As suas escolhas relativamente à utilização e divulgação das
                    suas informações pessoais
                  </strong>
                </p>
                <p>
                  <strong />
                  Nós damos-lhe escolhas relativamente à utilização e divulgação das suas 
                  informações pessoais para fins de marketing.  Poderá optar por não:
                </p>
                <ul>
                  <li>
                    <strong>
                      Receber comunicações de marketing da nossa parte:
                    </strong>{" "}
                    Se já não pretender receber comunicações de marketing da nossa parte, 
                    poderá optar por não as receber contactando-nos através de  e-mail{" "}
                    <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3#etd=::00c?Z9W00Y00_RAcvR900ww$" target="_blank">clicando aqui</a>.  
                    No pedido, inclua o seu nome, identifique as formas de comunicações de marketing 
                    que não deseja receber mais e inclua os endereços para os quais as comunicações 
                    são enviadas.  Por exemplo, se não pretender receber mais e-mails de marketing 
                    ou mailings diretos da nossa parte, informe-nos e indique o seu nome e e-mail 
                    ou endereço postal.  Adicionalmente, pode optar por não receber e-mails de marketing da nossa parte seguindo 
                    as instruções de cancelamento de subscrições incluídas em qualquer mensagem deste género. <br />
                    <br />
                  </li>
                  <li>
                    <strong>Receber lembretes da nossa parte:</strong> Se já não pretender receber lembretes médicos da nossa parte, 
                    poderá optar por não os receber contactando-nos através de  e-mail{" "}
                    <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3#etd=::00c?Z9W00Y00_RAcvR900ww$" target="_blank">clicando aqui</a>. 
                    Na resposta, indique o seu nome e o endereço de e-mail ou número de telefone no qual recebe os nossos lembretes.
                    <br />{" "}
                  </li>
                  <li>
                    <strong>
                    A nossa partilha das suas informações pessoais com afiliadas e parceiros externos:
                    </strong>{" "}
                    Se tiver optado anteriormente por receber comunicações de marketing das nossas 
                    afiliadas ou parceiros externos, poderá cancelar a nossa partilha das suas 
                    informações pessoais com estas partes para os seus 
                    fins de marketing direto contactando-nos através de e-mail{" "} 
                    <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3#etd=::00c?Z9W00Y00_RAcvR900ww$" target="_blank">clicando aqui</a>. 
                    Na comunicação, indique que já não pretende que partilhemos as suas 
                    informações pessoais com os nossos afiliados e/ou parceiros externos para os seus 
                    fins de marketing e inclua o seu nome e endereço de e-mail.
                  </li>
                </ul>
                <p>
                Procuraremos satisfazer o seu pedido assim que possível.  Tenha em atenção que, 
                se optar por cancelar estas subscrições, conforme descrito acima, não será 
                possível eliminar as suas informações pessoais das bases de dados das nossas 
                afiliadas com quem já partilhámos informações (ou seja, a partir da data em 
                que implementamos o seu pedido de cancelamento).  Todavia, empregaremos 
                esforços razoáveis para informar as nossas afiliadas do seu pedido. Tenha 
                também em atenção que, se optar por deixar de receber mensagens relacionadas 
                com marketing da nossa parte, poderemos continuar a enviar-lhe mensagens transacionais e 
                administrativas importantes, sendo que não é possível cancelar estas mensagens.
                </p>
                <p>
                  <strong>
                  Como aceder, alterar ou eliminar as suas informações pessoais
                  </strong>
                </p>
                <p>
                Se pretender rever, corrigir, atualizar, limitar ou eliminar as suas informações pessoais, 
                ou se pretender solicitar uma cópia eletrónica das suas informações pessoais para as 
                transmitir para outra empresa) dos seus dados pessoais (na medida em que estes direitos lhe sejam 
                conferidos pela lei aplicável), contacte-nos através de  e-mail{" "} 
                <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3#etd=::00c?Z9W00Y00_RAcvR900ww$" target="_blank">clicando aqui</a>.
                Responderemos ao seu pedido assim que possível, no prazo máximo de um mês após 
                a receção do pedido. Se determinadas circunstâncias causarem algum atraso na nossa resposta, 
                será prontamente notificado e indicar-lhe-emos uma data na qual irá receber a nossa resposta.  
                </p>
                <h2>TRANSFERÊNCIA INTERNACIONAL</h2>
                <p>
                As suas informações pessoais podem ser armazenadas e processadas em qualquer país 
                onde temos instalações ou prestadores de serviços e, ao utilizar o nosso Serviço ou 
                ao dar-nos o seu consentimento (quando exigido por lei), as suas informações podem 
                ser transferidas para países fora do seu país de residência, incluindo para os 
                Estados Unidos, que podem ter regras de proteção de dados diferentes do seu país. 
                Não obstante, implementamos medidas contratuais apropriadas e outras medidas para proteger informações 
                pessoais aquando da transferência para as nossas afiliadas ou terceiros noutros países.
                </p>
                <p>
                Alguns países fora do Espaço Económico Europeu (EEE) são reconhecidos pela Comissão 
                Europeia como tendo um nível adequado de proteção de dados 
                de acordo com as normas do EEE (poderá encontrar a lista completa destes países{" "} 
                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_pt" target="_blank">aqui</a>.
                Para transferências do EEE para países não considerados adequados pela Comissão Europeia, 
                assegurámos a implementação de medidas adequadas para proteger as suas Informações pessoais, 
                incluindo assegurar que o destinatário está vinculado por <strong>Cláusulas Contratuais-Tipo da UE</strong>. 
                Pode obter uma cópia destas medidas contactando o nosso 
                encarregado da proteção de dados em conformidade com a secção <i>"Contactar-nos"</i> abaixo.
                </p>
                <h2>SEGURANÇA</h2>
                <p>
                Procuramos utilizar medidas organizacionais, técnicas e administrativas desenvolvidas para proteger 
                as informações pessoais sob o nosso controlo.  Infelizmente, não é possível garantir 
                que uma transmissão de dados pela Internet ou sistema de armazenamento de dados seja 
                100% segura.  Se tiver razões para acreditar que a sua interação connosco já não é 
                segura (por exemplo, se acreditar que a segurança de uma conta que tem connosco foi comprometida), 
                notifique-nos de imediato em conformidade com a secção <i>"Contactar-nos"</i> abaixo.
                </p>
                <h2>PERÍODO DE RETENÇÃO</h2>
                <p>
                Mantemos as suas informações pessoais durante o período de tempo necessário ou 
                permitido em função das finalidades para as quais foram obtidas.  Os critérios 
                utilizados para determinar os períodos de retenção incluem:  (i) o período de 
                tempo durante o qual temos uma relação consigo e lhe prestamos o Serviço; 
                (ii) se existe uma obrigação legal à qual estamos sujeitos; e 
                (iii) se a retenção é aconselhável em função da nossa posição jurídica (como por exemplo, 
                relativamente a leis de prescrição, litigação ou investigações regulamentares).
                </p>
                <h2>SITES E SERVIÇOS DE TERCEIROS</h2>
                <p>
                Este Serviço poderá conter hiperligações para sites de terceiros. Esta Política 
                de Privacidade não aborda e nós não nos responsabilizamos pela privacidade, 
                informações ou práticas de terceiros, incluindo quaisquer terceiros que operem 
                um site ou serviço online (incluindo, sem limitação, qualquer aplicação) 
                disponibilizados através deste Serviço ou para o qual este Serviço inclui 
                uma ligação.  A disponibilização ou inclusão de uma ligação para qualquer site ou 
                propriedade no Serviço não implica a nossa recomendação ou a recomendação das nossas afiliadas.
                </p>
                <h2>CONTACTAR-NOS</h2>
                <p>
                A JNTL Consumer Health (Portugal) Limitada, situada em Lagoas Park, Edifício 9, 2740-262 Porto Salvo, 
                é a empresa responsável pela recolha, utilização e 
                divulgação de informações pessoais ao abrigo desta Política de Privacidade.</p>
                <p>
                Se tiver dúvidas acerca desta Política de Privacidade, 
                contacte-nos através de email clicando aqui, ou escreva-nos para o seguinte endereço: 
                </p>
                <p>
                  JNTL Consumer Health (Portugal) Limitada
                  <br />
                  Lagoas Park, Edifício 9.
                  <br />
                  2740-262 PORTO SALVO.
                </p>
                <p>
                Pode igualmente contactar o encarregado da proteção de dados do seu país ou região, se aplicável, através do e-mail{" "}
                  <a href="mailto:emeaprivacy@kenvue.com" target="_blank">
                    emeaprivacy@kenvue.com
                  </a>
                  .{" "}
                </p>
                <h2>
                  APRESENTAR UMA RECLAMAÇÃO JUNTO DE UMA ENTIDADE REGULADORA
                </h2>
                <p>
                Poderá apresentar uma reclamação junto de uma autoridade de controlo competente no seu país ou região.{" "}
                  <a
                    href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                    target="_blank"
                  >
                    Clique aqui
                  </a>{" "}
                  para obter as informações de contacto dessas autoridades. {" "}
                </p>
                <h2>ATUALIZAÇÕES A ESTA POLÍTICA DE PRIVACIDADE</h2>
                <p>
                Esta Política de Privacidade pode ser alterada. Quaisquer alterações a esta Política de 
                Privacidade entram em vigor quando publicarmos a Política de Privacidade revista no 
                Serviço. A sua utilização do Serviço após estas alterações significa que aceita 
                a Política de Privacidade revista. Recomendamos que reveja regularmente a Política de 
                Privacidade quando visitar o Serviço.   
                </p>
                <p>
                Esta política foi atualizada pela última vez em 02.06.2023.{" "}
                </p>
                <p> </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
